import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide5/image/imgPortadaGuia5.png"
import img1 from "@components/pageGuide/guides/guide5/image/img5.png"
import slider1 from "@components/pageGuide/guides/guide5/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide5/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide5/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Guía completa sobre los mensajes interactivos en WhatsApp
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    El uso de mensajes interactivos de WhatsApp permite a las empresas captar la atención de los clientes y mantenerlos comprometidos a lo largo de una conversación. Además, proporciona una experiencia mejorada al cliente, minimizar el error humano y aumentar las tasas de respuesta.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
  Los mensajes interactivos de WhatsApp no son más que características adicionales, disponibles solo con la API de WhatsApp, que permiten a las empresas ofrecer un servicio mejor y más interactivo a sus clientes. Lo que aumenta las posibilidades de conversión.
  <br /> <br />
Por eso, en esta guía no solo aprenderás a sobre el significado de este tipo de mensajes y los tipos. También aprenderás sobre los beneficios de tenerlos y cómo lo puedes tener desde nuestra plataforma omnicanal Beex.
  </p>
)

const data = {
  start: {
    support: "Guía | WhatsApp",
    title: title,
    description: textDescription,
    classtext: "guide__description5",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "¿Qué son los mensajes interactivos de WhatsApp?",
      },
      {
        key: 2,
        text: "4 tipos de mensajes interactivos.",
      },
      {
        key: 3,
        text: "Beneficios de los mensajes interactivos en WhatsApp.",
      },
      {
        key: 4,
        text: "¿Cómo obtener mensajes interactivos de WhatsApp?",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
